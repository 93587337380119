import React, { Fragment, useEffect, useState } from 'react'
import useScript from 'react-script-hook'
import consoleLogger from '../../../../modules/console-logger'
import isServerSide from '../../../../modules/is-server-side'
import Safe from 'react-safe'
import styled from 'styled-components'
import handlerWindowDlab from '../../../../bundles/BundleAds/modules/handler-window-dlab'
import { useCMPContext } from '../../../Sourcepoint/components/CMPProvider'
import isStolapp, { DEVICES } from '../../../../modules/is-stolapp'
import isStolApp from '../../../../modules/is-stolapp'

const Wrapper = styled.div`
  padding: ${props => props.theme.sizes.gapEdge};
  @media print {
    display: none;
  }
`

const Taboola = ({...props}) => {
  const [consoleLog] = consoleLogger('Taboola')
  const { dlabOption } = handlerWindowDlab('ads', { sub: 'Taboola' })
  const {consentReady} = useCMPContext()
  const accountNameWeb = "firstavenuegmbh-stolit"
  const accountNameAndroid = "firstavenuegmbh-stolitandroidapp"
  const accountNameIos = "firstavenuegmbh-stolitiosapp"
  let accountSrc = `//cdn.taboola.com/libtrc/${accountNameWeb}/loader.js`
  if (isStolApp(DEVICES.android)) {
    accountSrc = `//cdn.taboola.com/libtrc/${accountNameAndroid}/loader.js`
  }
  if (isStolApp(DEVICES.ios)) {
    accountSrc = `//cdn.taboola.com/libtrc/${accountNameIos}/loader.20241104.1_b1-PR-77957-DEV-175963-prevent-click-propagation-and-send-beacon-01650094108.js`
  }
  const [loading, error] = useScript({
    src: consentReady ? accountSrc : null,
    checkForExisting: true,
    id: 'tb_loader_script',
    async: 1
  })
  const [firstLoad, setFirstLoad] = useState(false)
  useEffect(() => {
    consoleLog('window var Taboola', window._taboola)
    window._taboola = window._taboola || [];

    if (!loading && consentReady && window._taboola && !firstLoad) {
      if (!dlabOption["start"]) {
        window._taboola.push({article:'auto'});
        if(window.performance && typeof window.performance.mark == 'function')
        {
          window.performance.mark('tbl_ic');
        }
        consoleLog('first page load', 'success')
      }
      if (dlabOption["start"]) {
        window._taboola.push({notify:'newPageLoad'});
        window._taboola.push({article:'auto', url:location?.href});
        consoleLog('new page load', 'success')
      }
      window._taboola.push({mode: 'alternating-thumbnails-a', container: 'taboola-below-article-thumbnails', placement: 'Below Article Thumbnails', target_type: 'mix'});
      consoleLog('load widget', 'success')
      dlabOption["start"] = true
      setFirstLoad(true)
    }

  }, [loading, consentReady])

  return (
    <Wrapper {...props}>
      {!isServerSide() && (
        <Fragment>
          <Safe.div id="taboola-below-article-thumbnails" />
        </Fragment>
      )}
    </Wrapper>
  )
}
export default Taboola
