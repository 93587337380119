import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'
import HeaderDetailArticle from './components/HeaderDetailArticle'
import ContainerArticle from '../../../../components/ContainerArticle'
import ModifyAndShareDetailArticle from './components/ModifyAndShareDetailArticle'
import WidgetRelated from '../../widgets_line/WidgetRelated'
import ContainerAd from '../../../../components/ContainerAd'
import Breadcrumb from '../../../../components/Breadcrumb'
import ImageDetailArticle from './components/ImageDetailArticle'
import VideoDetailArticle from './components/VideoDetailArticle'
import hasItems from '../../../../modules/has-items'
import CommentsDetailArticle from './components/CommentsDetailArticle'
import isStolApp from '../../../../modules/is-stolapp'
import DistrictsNav from '../../../../components/DistrictsNav'
import { setStoredItem } from '../../../../modules/stored-item'
import { withRouter } from 'react-router-dom'
import { setFont, getFont } from '../../../../modules/font'
import checkForValueInUserAgent from '../../../../modules/check-for-value-in-useragent'
import CloudTag from './components/CloudTag'
import AuthorInfo from './components/AuthorInfo'
import loadable from '@loadable/component'
import pushEventToDataLayer from '../../../../modules/push-event-to-data-layer'
import Block from '../../../../layouts/Blocks'
import Schema from '../../../../vendor/Schema/index'
import { jobsSLCSlotIDs } from '../../../../config/layouts/article'
import communityInfo from './components/communityInfo'
import ReadingRecommendation from './components/ReadingRecommendation'
const Map = loadable(() => import(/* webpackChunkName: "Map" */ '../../../../components/Map'))

const ContentDetailArticle = loadable(() =>
  import(/* webpackChunkName: "ContentDetailArticle", webpackPrefetch: true */ './components/ContentDetailArticle'),
)

const StyledMap = styled(Map)`
  height: 300px;
`
const StyledContainerAd = styled(ContainerAd)`
  margin: 0 0 26px;
  justify-content: space-evenly;
  > div {
    margin: 0 10px 0;
  }
  ${(props) =>
    !props.pr &&
    css`
      margin-top: -30px;
      @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
        margin-top: -20px;
      }
    `}
`
const StyledHeaderDetailArticle = css`
  margin-top: 20px;
  margin-bottom: 40px;
  margin-left: -40px;
  width: calc(100% + 80px);
`

const StyledImageDetailArticle = styled(ImageDetailArticle)`
  ${StyledHeaderDetailArticle}
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: calc(100% + (2 * ${(props) => props.theme.mobile.sizes.gapEdge}));
    margin-left: -${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: -${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const StyledVideoDetailArticle = styled(VideoDetailArticle)`
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    ${StyledHeaderDetailArticle}
    @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
      margin-bottom: ${(props) => props.theme.mobile.sizes.gapEdge};
      margin-left: -${(props) => props.theme.mobile.sizes.gapEdge};
      width: calc(100% + (2 * ${(props) => props.theme.mobile.sizes.gapEdge}));
    }
  }
`

const StyledCloudTag = styled(CloudTag)`
  margin: 0;
  padding: 0 0 16px;
`

const StyledWidgetRelated = styled(WidgetRelated)`
  margin-bottom: 26px;
`

const StyledContainerArticle = styled(ContainerArticle)`
  margin-bottom: 0;
`
const StyledContainerArticleComments= styled(ContainerArticle)`
  background-color: #FAFAFA;
  margin-bottom: 0;
`

const StyledAuthorInfo = styled(AuthorInfo)``

const StyledCommunityInfo = styled(StyledAuthorInfo)`
  margin-bottom: 25px;
  margin-top: 25px;
`

const Wrapper = styled.div``

//moved inline css to this component
const WrapperArticle = styled.div`
  background-color: ${(props) => (props.pr ? props.theme.color.pr : props.theme.color.backgroundContent)};

  //inline css
  .attribute-image > img {
    max-width: 100%;
  }
  .img-inline {
    text-align: center;
  }
  .img-inline > a:nth-child(2) > i > svg {
    display: none;
  }

  .img-inline {
    a {
      line-break: anywhere;
    }
  }
  .uk-img-preserve {
    max-width: 220px;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
    width: 100%;
  }
  iframe {
    border: none;
  }
`

const WrapperUnderDistrictNav = styled.div`
  background-color: #fff;
`

const DetailArticle = ({ data, adTargeting, location, ...props }) => {
  ContentDetailArticle.preload()
  const [fontSize, setFontSize] = useState(getFont())
  useEffect(() => {
    if (window.sessionStorage.getItem('fireTentacles') !== 'start') {
      if (typeof window.postMessage === 'function') {
        window.postMessage('activateTentacles', window.origin)
      }
    }
    return () => {
      window.sessionStorage.setItem('fireTentacles', 'article')
    }
  }, [])
  const enlargeFont = () => {
    let newFont = setFont(fontSize, '+', () =>
      setStoredItem('fontSize ' + location.pathname, (fontSize + 1).toString(), true),
    )
    if (newFont !== fontSize) {
      setFontSize(newFont)
    }
  }

  const reduceFont = () => {
    let newFont = setFont(fontSize, '-', () =>
      setStoredItem('fontSize ' + location.pathname, (fontSize - 1).toString(), true),
    )
    if (newFont !== fontSize) {
      setFontSize(newFont)
    }
  }

  const headmedia = {
    local: data.local,
    type: data.type,
    partner: data.partner,
    image: data.image,
  }
  let breadcrumb = []
  breadcrumb.push(data.breadcrumb.department)
  if (
    hasItems(data.breadcrumb.subDepartment) &&
    data.breadcrumb.subDepartment.name !== data.breadcrumb.subDepartment.name
  ) {
    breadcrumb.push(data.breadcrumb.subDepartment)
  }
  const isCondulence = data.articleLayout === 'condulence'
  const isSportnews = data.partner === 'sportnews'
  const isCommunityNews =
    data && data.type && data.breadcrumb
      ? data.type === 'UGCGemeindeNews' ||
        data.breadcrumb.department.name.toLowerCase().replaceAll(' ', '') === 'gemeindeblatt'
      : false
  const community =
    data.breadcrumb && data.breadcrumb.subDepartment && data.breadcrumb.subDepartment.name
      ? data.breadcrumb.subDepartment.name.toLowerCase().replaceAll(' ', '')
      : null
  const plus = data.plus
  const articleLayout = data.articleLayout ? data.articleLayout : data.partner

  if (data && data.districts && data.districts[0]) {
    pushEventToDataLayer('LocalArticle', ['DistrictName', data.districts[0].name])
  }
  return (
    <Wrapper>
      <WrapperArticle
        pr={data && data.articleLayout && data.articleLayout === 'pr'}
        isStolApp={isStolApp()}
        isIphoneX={checkForValueInUserAgent('iphonex')}
        {...props}
      >
        {!isStolApp() && <Breadcrumb bookmark={{ show: true, state: false }} crumbs={breadcrumb} />}

        <StyledContainerArticle>
          {!isStolApp() && <Schema data={data} />}
          <HeaderDetailArticle
            title={data.title}
            headline={data.breadcrumb.headline}
            date={data.date}
            description={data.description}
            plus={data.plus}
            fontSize={fontSize}
            isCommunityNews={isCommunityNews}
          />

          {!isStolApp() && !isCommunityNews && (
            <ModifyAndShareDetailArticle
              position="top"
              enlargeFontCallBack={enlargeFont}
              reduceFontCallBack={reduceFont}
            />
          )}
          {data && data.image && data.image.url && (!data.video || data.video.length === 0) && (
            <StyledImageDetailArticle
              fontSize={fontSize}
              noLazyLoading={true}
              communityLogo={isCommunityNews && data.partnerUrl}
              view="big"
              department={data && data.breadcrumb && data.breadcrumb.department}
              duplicateDescription={
                data.content && data.image.description && data.content.includes(data.image.description)
              }
              {...headmedia}
            />
          )}
          {data && data.image && data.image.url && data.video && (
            <StyledVideoDetailArticle
              fontSize={fontSize}
              plus={plus}
              inHeader
              video={data.video}
              showAds={data.showAds}
              copyright={data.video.copyright}
              adTargeting={adTargeting}
              {...headmedia}
            />
          )}

          {data.author != null && data.partner != 'sportnews' && !isCommunityNews && (
            <StyledAuthorInfo author={data.author}></StyledAuthorInfo>
          )}

          {!isCondulence && (
            <Fragment>
              {!isStolApp() && isCommunityNews && (
                <ModifyAndShareDetailArticle
                  position="top"
                  enlargeFontCallBack={enlargeFont}
                  reduceFontCallBack={reduceFont}
                />
              )}
              {isCommunityNews && communityInfo[community] && (
                <StyledCommunityInfo author={communityInfo[community]} isCommunityNews={isCommunityNews} />
              )}

              <ContentDetailArticle
                htmlContent={data.content}
                isCommunityNews={isCommunityNews}
                plus={plus}
                plusTeaser={data.sPlusTeaser}
                plusContent={data.sPlusContent}
                embeds={data.embeds}
                showAds={data.showAds}
                editor={data.editor}
                fontSize={fontSize}
                isSportnews={isSportnews}
                adTargeting={adTargeting}
                articleLayout={articleLayout}
                author={data.author}
                tags={data.tags}
              />
              {data.tags && data.tags.length > 1 && data.articleLayout !== 'pr' && (
                <StyledCloudTag plus={data.plus && !isSportnews} tags={data.tags} />
              )}
            </Fragment>
          )}
        </StyledContainerArticle>
      </WrapperArticle>

      {hasItems(data.related) && <StyledWidgetRelated articles={data.related} />}
      {/*!isCondulence && data.showAds && (
        <StyledContainerAd adIds={['S3', 'S4']} pr={data.articleLayout === 'pr'} noGapEdge={true} />
      )*/}
      {!isCondulence && (
        <Block
          view="jobs"
          numberItems={{
            desktop: 2,
            tablet: 2,
            mobile: 2,
          }}
          slotIds={jobsSLCSlotIDs}
          noGapEdge={true}
        />
      )}
      <WrapperUnderDistrictNav>
        {data.geo && <StyledMap lat={data.geo.lat} lng={data.geo.lng} height={300} />}
        {data.showAds && <ReadingRecommendation location={location} />}
        {!isCondulence && hasItems(data.districts) && <DistrictsNav selected={data.districts} open />}
        <StyledContainerArticleComments>
          <CommentsDetailArticle
            articleId={data.id}
            comments={data && data.comments}
            commentsEnabled={data.commentsEnabled && !isSportnews}
            isCondulence={isCondulence}
            plus={plus}
          />
        </StyledContainerArticleComments>
      </WrapperUnderDistrictNav>
    </Wrapper>
  )
}

DetailArticle.propTypes = {
  data: PropTypes.shape({
    breadcrumb: PropTypes.arrayOf({
      name: PropTypes.string,
      route: PropTypes.string,
    }),
    ...HeaderDetailArticle.propTypes,
    ...WidgetRelated.propTypes,
  }),
}

export default withRouter(withTheme(DetailArticle))

export {
  StyledContainerArticle as StyledContainerArticle0DetailArticle,
  StyledImageDetailArticle as StyledImageDetailArticle0DetailArticle,
}
