import translate from '../../../../../../modules/translate'
import React from 'react'
import styled from 'styled-components'
import MessageText from '../../../../../UserGeneratedContent/components/FormUGC/components/MessageText'
import Icon from '../../../../../../components/Icon'

const StyledMessageText = styled(MessageText)`
  justify-content: center;
`

const StyledOpenDialog = styled.div`
  text-decoration-line: underline;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`

const MailIcon = styled(Icon)`
  margin-right: 0.3em;
  font-size: ${(props) => props.theme.fontSize.title2};
`
const EmailMessageTextNotVerified = ({ onClick }) => (
  <StyledMessageText>
    <MailIcon icon="email" />
    <StyledOpenDialog onClick={onClick}>{translate('email_verification_open_dialog')}</StyledOpenDialog>
  </StyledMessageText>
)

export default EmailMessageTextNotVerified
