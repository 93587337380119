import React, { useState } from 'react'
import styled from 'styled-components'
import EmailMessageTextNotVerified from './components/MessageTextNotVerified'
import EmailDialogNotVerified from './components/DialogNotVerified'
import DialogConfirmationNotVerified from '../NotVerified/components/DialogConfirmationNotVerified'
import { actionPOST } from '../../../../modules/fetch-data'
import userSession from '../../modules/user-session'
import options from '../../../../config/options'

const NotVerifiedWrapper = styled.div``

const EmailNotVerified = () => {
  const [modalOpen, setModalOpen] = useState(true)
  const [confirmationOpen, setConfirmationOpen] = useState('')
  const onEmailSend = () => {
    actionPOST([], {
      access: userSession.getAccessToken(),
      path: options.Api.host + 'v1/resend-verification-mail',
    })
      .then((res) => {
        setConfirmationOpen(res.success ? res.email : 'failure')
        setModalOpen(false)
      })
      .catch((err) => {})
  }

  return (
    <NotVerifiedWrapper>
      <EmailDialogNotVerified open={modalOpen} onEmailSend={onEmailSend} onClose={() => setModalOpen(false)} />

      <DialogConfirmationNotVerified confirmation={confirmationOpen} onClose={() => setConfirmationOpen(undefined)} />

      {!modalOpen && confirmationOpen === '' && <EmailMessageTextNotVerified onClick={() => setModalOpen(true)} />}
    </NotVerifiedWrapper>
  )
}

export default EmailNotVerified
