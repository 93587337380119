import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useUserContext } from '../../../../../../components/ContextUser'
import EmailNotVerified from '../../../../components/EmailNotVerified'

const Wrapper = styled.div``

const Account = styled.div``

const AccountPiano = (props) => {
  const { profile } = useUserContext()

  useEffect(() => {
    window.tp = window['tp'] || []
    window.tp.push([
      'init',
      function () {
        window.tp.myaccount.show({
          displayMode: 'inline',
          containerSelector: '#splus-account',
        })
      },
    ])
  }, [])

  return (
    <Wrapper {...props}>
      {profile && profile.emailVerified === false && <EmailNotVerified />}
      <Account id="splus-account" />
    </Wrapper>
  )
}

export default AccountPiano
