import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import translate from '../../../../../../modules/translate'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import React from 'react'

const DialogConfirmationNotVerified = ({ confirmation = '', onClose = () => {} }) => (
  <Dialog
    open={!!confirmation}
    aria-labelledby="email-confirmation-dialog-title"
    aria-describedby="email-confirmation-dialog-description"
  >
    <DialogTitle id="email-confirmation-dialog-title">
      {confirmation !== 'failure'
        ? translate('email_verification_send_success', { email: confirmation })
        : translate('email_verification_send_failure')}
    </DialogTitle>
    <DialogContent>
      {confirmation !== 'failure' && (
        <DialogContentText id="email-confirmation-dialog-description">
          {translate('email_verification_instructions')}
        </DialogContentText>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {translate('close')}
      </Button>
    </DialogActions>
  </Dialog>
)

export default DialogConfirmationNotVerified
